import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { RestApiClient } from '@models/rest.api-client';
import { ServerError } from '@models/server-error';
import { Message } from '@interfaces/alert';
import { ErrorHandlerService } from '@services/error-handler.service';

export const invitationGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const errorHandlerService = inject(ErrorHandlerService);
  const restApiClient = new RestApiClient();

  const token = route.params['inviteToken'] ?? '';

  try {
    await restApiClient
      .method('GET')
      .route(`/invitation/accept/${token}`)
      .withAuthToken()
      .response<Message>();

    router.navigateByUrl('evidencije');
  } catch (error) {
    errorHandlerService.handleError(new ServerError(error));
  }

  return true;
};
