import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[passwordStrength]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordStrengthDirective,
      multi: true
    }
  ]
})
export class PasswordStrengthDirective implements Validator {
  @Input('passwordStrength') passwordStrength: RegExp = /^/;

  validate(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    
    if (!this.passwordStrength.test(password)) {
      return { 'passwordStrength': true };
    }

    return null;
  }
}
