import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { z, ZodError } from 'zod';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

import { Empty } from '@helpers/array';
import { slideFromTop } from '@animations/animation';
import { TranslationPipe } from '@pipes/translation.pipe';
import { SelectedTenant } from '@interfaces/tenant';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'select-tenant',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    TranslationPipe,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
  ],
  templateUrl: './select-tenant.component.html',
  styleUrls: ['./select-tenant.component.scss'],
  animations: [slideFromTop],
})
export class SelectTenantComponent {
  public readonly componentName: string = 'SelectTenantComponent';
  @Input() public userName: string = '';
  @Input() public tenants: Array<SelectedTenant> = [];
  @Input() public activeInvites: number | null = null;
  @Output() public onLogout: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onToggleSidebar: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onSelectTenant: EventEmitter<SelectedTenant> =
    new EventEmitter<SelectedTenant>();
  @Output() public onSendInvite: EventEmitter<{
    tenantIds: Array<number>;
    emails: Array<string>;
  }> = new EventEmitter<{ tenantIds: Array<number>; emails: Array<string> }>();
  public selectedTenants: Array<Partial<SelectedTenant>> = [];
  public invite: { email: string } = { email: '' };
  public formSubmitted: boolean = false;
  public waitingForResponse: boolean = false;
  public validationError: { message: string; invalidEmail: string } = {
    message: '',
    invalidEmail: '',
  };

  constructor(private messageService: MessageService) {}

  /**
   * Validates the email input in the invite form.
   * @returns `true` if the email input is valid, `false` otherwise.
   */
  private validation(): boolean {
    const emailSchema = z.object({
      email: z.array(
        z.string().min(1, 'emailRequiredField').email('emailStructureInvalid')
      ),
    });

    const emails = this.invite.email.split(' ');
    try {
      this.validationError = { message: '', invalidEmail: '' };
      emailSchema.parse({ email: emails });

      return true;
    } catch (error) {
      if (error instanceof ZodError) {
        this.validationError = {
          message: error.errors[0].message,
          invalidEmail: emails[error.errors[0].path[1] as number],
        };
      }
    }

    return false;
  }

  /**
   * Emits an event to notify that the user has logged out.
   */
  public logout(): void {
    this.onLogout.emit();
  }

  /**
   * Emits an event to notify that the sidebar should be toggled.
   * @param sidebar - The name of the sidebar to toggle.
   */
  public toggleSidebar(sidebar: string): void {
    this.onToggleSidebar.emit(sidebar);
  }

  /**
   * Emits an event to notify that a tenant has been selected.
   * @param tenant - The tenant that was selected.
   */
  public selectTenant(tenant: SelectedTenant): void {
    if (!tenant.active) {
      return;
    }

    this.onSelectTenant.emit(tenant);
  }

  /**
   * Sends an invite to the selected tenants with the provided email addresses.
   *
   * This method first checks if any tenants are selected, and if not, displays
   * a warning message.
   * It then validates the email addresses, and if valid, emits an event with the
   * tenant IDs and email addresses.
   * After the invite is sent, the form is reset and the waiting state is cleared.
   */
  public sendInvite() {
    if (Empty(this.selectedTenants)) {
      this.messageService
        .translate(this.componentName)
        .warning('selectionNeeded')
        .showMessage();
      return;
    }

    this.formSubmitted = true;

    if (!this.validation()) {
      return;
    }

    this.waitingForResponse = true;

    this.onSendInvite.emit({
      tenantIds: this.selectedTenants.map((tenant) => tenant.id ?? 0),
      emails: this.invite.email.split(' '),
    });

    this.waitingForResponse = false;
    this.formSubmitted = false;

    this.selectedTenants = [];
    this.invite = { email: '' };
  }
}
