import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { MessageService as ToastService } from 'primeng/api';

import { MessageService } from '../../services/message.service';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    providers: [ToastService],
    standalone: true,
    imports: [ToastModule],
})
export class MessageComponent implements OnInit, OnDestroy {
  private subscriptions: Array<Subscription> = [];

  constructor(
    private toastService: ToastService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    const subscription = this.messageService.alertObject.subscribe((alerts) => {
      this.toastService.clear();
      this.toastService.addAll(alerts);
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    if (!this.subscriptions) {
      return;
    }

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
