<ng-container *ngIf="isReady | async">
  <p-panel styleClass="consent-panel">
    <ng-template pTemplate="header">
      <img src="/assets/img/cookie.png" alt="Swing Consulting logo" />
      <h4 class="panel-header">
        {{ "cookieConsentRules" | translation : componentName }}
      </h4>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="panel-content">
        <p class="consent-text">
          <strong>{{ "appName" | translation : componentName }}</strong>
          {{ "cookieConsentInfoText" | translation : componentName }}
          <a href="#">{{ "privacyRights" | translation : componentName }}</a
          >.
        </p>
        <div class="consent-button-container">
          <button
            pButton
            class="p-button-primary action-button"
            label="{{ 'acceptAll' | translation : componentName }}"
            (click)="acceptCookies('all')"
          ></button>
          <button
            pButton
            class="p-button-primary action-button"
            label="{{ 'acceptBasic' | translation : componentName }}"
            (click)="acceptCookies('neccesary')"
          ></button>
        </div>
        <div class="consent-button-container">
          <button
            pButton
            label="{{ 'manageCookies' | translation : componentName }}"
            class="p-button-secondary action-button"
            (click)="showCookieSettings()"
          ></button>
        </div>
      </div>
    </ng-template>
  </p-panel>

  <p-dialog
    styleClass="consent-dialog"
    [(visible)]="cookieSettingsVisible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
  >
    <ng-template pTemplate="header">
      <h3 class="dialog-header">
        {{ "cookieSettings" | translation : componentName }}
      </h3>
    </ng-template>

    <ng-template pTemplate="content">
      <div class="dialog-content">
        <p class="consent-text">
          {{ "cookieConsentSettingsText" | translation : componentName }}
        </p>
        <div class="cookie-option">
          <span class="cookie-option-label">{{
            "basicCookiesOnly" | translation : componentName
          }}</span>
          <p-inputSwitch
            class="input-switch"
            [(ngModel)]="cookieConsent.required"
            [readonly]="true"
          >
          </p-inputSwitch>
        </div>
        <div class="cookie-option">
          <span class="cookie-option-label">{{
            "errorReporting" | translation : componentName
          }}</span>
          <p-inputSwitch
            class="input-switch"
            [(ngModel)]="cookieConsent.bugReport"
          >
          </p-inputSwitch>
        </div>
        <div class="cookie-option">
          <span class="cookie-option-label">{{
            "platformInfo" | translation : componentName
          }}</span>
          <p-inputSwitch
            class="input-switch"
            [(ngModel)]="cookieConsent.deviceInfo"
          >
          </p-inputSwitch>
        </div>
        <div class="cookie-option">
          <span class="cookie-option-label">{{
            "locationInfo" | translation : componentName
          }}</span>
          <p-inputSwitch
            class="input-switch"
            [(ngModel)]="cookieConsent.location"
          >
          </p-inputSwitch>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="consent-button-container">
        <button
          pButton
          class="p-button-primary action-button"
          label="{{ 'saveChanges' | translation : componentName }}"
          (click)="consent()"
        ></button>
        <button
          pButton
          class="p-button-secondary action-button"
          label="{{ 'acceptAll' | translation : componentName }}"
          (click)="acceptCookies('all')"
        ></button>
        <button
          pButton
          class="p-button-primary p-button-text action-button"
          label="{{ 'withdraw' | translation : componentName }}"
          (click)="hideCookieSettings()"
        ></button>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>
