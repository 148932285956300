import { NgModule } from '@angular/core';
import axios from 'axios';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  Operation,
  from,
  fromPromise,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import { environment } from '@environments/environment';
import { SecureLocalStorage } from '@models/secure-local-storage';
import { ServerError } from '@models/server-error';
import { AuthToken } from '@interfaces/authentication';
import { ErrorHandlerService } from '@services/error-handler.service';

const uri = `${environment.API_URL}/repository/graphql`;

const createAxiosLink = (): ApolloLink =>
  new ApolloLink((operation: Operation) =>
    fromPromise(
      axios({
        url: uri,
        method: 'POST',
        data: {
          query: operation.query.loc?.source.body,
          variables: operation.variables,
        },
        headers: operation.getContext()['headers'],
      }).then((response) => ({
        data: response.data.data,
        errors: response.data.errors,
      }))
    )
  );

const createErrorLink = (
  errorHandlerService: ErrorHandlerService
): ApolloLink =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors?.length) {
      graphQLErrors.forEach((graphQLError) =>
        errorHandlerService.handleError(new ServerError(graphQLError))
      );
    }

    if (networkError) {
      errorHandlerService.handleError(new ServerError(networkError));
    }
  });

const createAuthLink = () =>
  setContext(() => {
    const authToken: AuthToken = new SecureLocalStorage()
      .get('authToken')
      .decrypt()
      .toJSON().value;
    return {
      headers: {
        authorization: authToken ? `Bearer ${authToken.token}` : '',
      },
    };
  });

export function createApollo(
  errorHandlerService: ErrorHandlerService
): ApolloClientOptions<any> {
  return {
    link: from([
      createErrorLink(errorHandlerService),
      createAuthLink(),
      createAxiosLink(),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [ErrorHandlerService],
    },
  ],
})
export class GraphQLModule {}
