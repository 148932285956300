<p-sidebar
  styleClass="sidebar-wrapper"
  [(visible)]="visible"
  position="left"
  transitionOptions="0.5s"
  (onHide)="hideSidebar()"
>
  <ng-template pTemplate="header">
    <h2>{{ "receivedInvites" | translation : componentName }}</h2>
  </ng-template>

  <ng-template pTemplate="content">
    <p-table
      [value]="invites"
      tableStyleClass="invites-table"
      [scrollable]="true"
      scrollHeight="80vh"
      responsiveLayout="stack"
      breakpoint="600px"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "tenant" | translation : componentName }}</th>
          <th>{{ "from" | translation : componentName }}</th>
          <th>{{ "active" | translation : componentName }}</th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-invite>
        <tr>
          <td>
            <span class="column-title">{{
              "tenant" | translation : componentName
            }}</span>
            {{ invite.tenant }}
          </td>
          <td>
            <span class="column-title">{{
              "from" | translation : componentName
            }}</span>
            {{ invite.from }}
          </td>
          <td>
            <span class="column-title">{{
              "active" | translation : componentName
            }}</span>
            <span class="{{ invite.active.toString() }}">{{
              invite.active.toString() | translation : componentName
            }}</span>
          </td>
          <td>
            <div class="button-container">
              <ng-container *ngIf="invite.active; else inactive">
                <button
                  pButton
                  class="p-button-success p-button-text p-button-raised action-button"
                  icon="pi pi-check"
                  [disabled]="waitingForResponse"
                  (click)="handleInvite('accept', invite.token)"
                ></button>
                <button
                  pButton
                  class="p-button-danger p-button-text p-button-raised action-button"
                  icon="pi pi-times"
                  [disabled]="waitingForResponse"
                  (click)="handleInvite('reject', invite.token)"
                ></button>
              </ng-container>

              <ng-template #inactive>
                <button
                  pButton
                  class="p-button-danger p-button-text p-button-raised action-button"
                  icon="pi pi-trash"
                  [disabled]="waitingForResponse"
                  (click)="handleInvite('reject', invite.token)"
                ></button>
              </ng-template>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-sidebar>
