import { Component } from '@angular/core';
import { NgIf } from '@angular/common';

import {
  BreadcrumbComponent as XngBreadcrumbComponent,
  BreadcrumbItemDirective,
} from 'xng-breadcrumb';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'breadcrumb',
  standalone: true,
  imports: [NgIf, RouterLink, XngBreadcrumbComponent, BreadcrumbItemDirective],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {}
