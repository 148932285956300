import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { z, ZodError } from 'zod';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';

import { TranslationPipe } from '@pipes/translation.pipe';
import { ServerError } from '@models/server-error';
import { RecoverPassword, User } from '@interfaces/authentication';
import { ErrorHandlerService } from '@services/error-handler.service';
import { RestApiClient } from '@models/rest.api-client';

@Component({
  selector: 'forgot-password',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    AsyncPipe,
    TranslationPipe,
    ButtonModule,
    FloatLabelModule,
    InputTextModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public readonly componentName: string = 'ForgotPasswordComponent';
  @ViewChild('passwordForm', { static: false }) public passwordForm!: NgForm;
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public user: Partial<User> = { email: '' };
  public formSubmitted: boolean = false;
  public waitingForResponse: boolean = false;
  public validationError: {
    email: Array<string>;
  } = { email: [] };
  public gotSuccessfulResponse: boolean = false;
  public timeout: number = 0;
  private restApiClient: RestApiClient;

  constructor(private errorHandlerService: ErrorHandlerService) {
    this.restApiClient = new RestApiClient();
  }

  public ngOnInit(): void {
    this.timeout = window.setTimeout(() => {
      this.isReady$.next(true);
    }, 500);
  }

  /**
   * Validates the form data for the password recovery flow.
   *
   * This method uses the Zod library to validate the `email` field of the `user`
   * object. If the validation is successful, it returns `true`. If there are any
   * validation errors, it populates the `validationError` object with the error
   * messages and returns `false`.
   *
   * @returns `true` if the form data is valid, `false` otherwise.
   */
  private validation(): boolean {
    const FormData = z
      .object({
        email: z
          .string()
          .min(1, 'emailRequiredField')
          .email('emailStructureInvalid'),
      })
      .strict();

    try {
      FormData.parse(this.user);

      return true;
    } catch (error) {
      if (error instanceof ZodError) {
        const fieldErrors = error.flatten().fieldErrors;

        this.validationError = {
          email: fieldErrors['email'] ?? [],
        };
      }
    }

    return false;
  }

  /**
   * Sends an email for password recovery.
   *
   * This method is responsible for handling the password recovery flow. It first
   * checks if the form is valid, and if so, it sends a POST request to the
   * '/password/recover' endpoint with the user's email. If the request is
   * successful, the method sets the `waitingForResponse` flag to false.
   * If there is an error, it is handled by the `errorHandlerService`.
   */
  public async sendEmailForPasswordRecovery(): Promise<void> {
    this.formSubmitted = true;

    if (!this.validation()) {
      return;
    }

    this.waitingForResponse = true;

    try {
      this.restApiClient
        .method('POST')
        .route('/password/recover')
        .body(this.user)
        .response<RecoverPassword>();

      this.gotSuccessfulResponse = true;
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.waitingForResponse = false;
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
