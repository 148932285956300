<ng-container *ngIf="isReady$ | async">
  <div class="content-container">
    <div class="form-container">
      <div class="form-header">
        <div class="logo-container">
          <img
            src="/assets/img/logo/logo-name-125.png"
            srcset="
              /assets/img/logo/logo-name-125.png 125w,
              assets/img/logo/logo-name-100.png  100w,
              assets/img/logo/logo-name-75.png    75w,
              assets/img/logo/logo-name-50.png    50w
            "
            sizes="(max-width: 400px) 50px, (max-width: 500px) 75px,
                  (max-width: 768px) 100px, 125px"
            alt="Swing Consulting logo"
          />
        </div>
        <div class="form-content">
          <h3>{{ "confirmSignup" | translation : componentName }}</h3>
          <div class="form-note">
            {{ "sentEmailForVerification" | translation : componentName }}
          </div>
          <div class="warning-text">
            {{ "warning" | translation : componentName }}
          </div>
        </div>
      </div>

      <div class="form-content">
        <button
          pButton
          type="button"
          class="form-submit-button"
          label="{{ 'send' | translation : componentName }}"
          [disabled]="waitingForResponse"
          (click)="resendVerificationEmail()"
        ></button>
      </div>
    </div>
  </div>
</ng-container>
