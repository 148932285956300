import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { SecureLocalStorage } from '@models/secure-local-storage';
import { User } from '@interfaces/authentication';

export const verificationGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const secureLocalStorage = new SecureLocalStorage();

  let allow: boolean = false;

  if (secureLocalStorage.has('user')) {
    const user: User = secureLocalStorage.get('user').toJSON().value;
    allow = user.verified;
  }

  return allow ? true : router.parseUrl('/potvrda');
};
