import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { z, ZodError } from 'zod';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { cacheStorageImage } from '@helpers/cache-storage';
import { TranslationPipe } from '@pipes/translation.pipe';
import { ServerError } from '@models/server-error';
import { Authentication, User } from '@interfaces/authentication';
import { ActiveTenant } from '@interfaces/tenant';
import { ErrorHandlerService } from '@services/error-handler.service';
import { RestApiClient } from '@models/rest.api-client';
import { SecureLocalStorage } from '@models/secure-local-storage';

@Component({
  selector: 'login',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    RouterLink,
    AsyncPipe,
    TranslationPipe,
    ButtonModule,
    FloatLabelModule,
    InputTextModule,
    PasswordModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public readonly componentName: string = 'LoginComponent';
  @ViewChild('loginForm', { static: false }) public loginForm!: NgForm;
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public user: Partial<User> = { email: '', password: '' };
  public formSubmitted: boolean = false;
  public waitingForResponse: boolean = false;
  public validationError: {
    email: Array<string>;
    password: Array<string>;
  } = { email: [], password: [] };
  public timeout: number = 0;
  private restApiClient: RestApiClient;
  private secureLocalStorage: SecureLocalStorage;

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.restApiClient = new RestApiClient();
    this.secureLocalStorage = new SecureLocalStorage();
  }

  public ngOnInit(): void {
    this.timeout = window.setTimeout(() => {
      this.isReady$.next(true);
    }, 500);
  }

  /**
   * Validates the user input for the email and password fields.
   *
   * This method uses the Zod library to define a schema for the user object,
   * which includes the email and password fields. It then attempts to parse
   * the user object against the schema. If the parsing is successful, the
   * method returns `true`, indicating that the user input is valid. If the
   * parsing fails, the method populates the `validationError` object with
   * the error messages for the email and password fields, and returns `false`.
   *
   * @returns `true` if the user input is valid, `false` otherwise.
   */
  private validation(): boolean {
    const FormData = z
      .object({
        email: z
          .string()
          .min(1, 'emailRequiredField')
          .email('emailStructureInvalid'),
        password: z.string().min(1, 'passwordRequiredField'),
      })
      .strict();

    try {
      FormData.parse(this.user);

      return true;
    } catch (error) {
      if (error instanceof ZodError) {
        const fieldErrors = error.flatten().fieldErrors;

        this.validationError = {
          email: fieldErrors['email'] ?? [],
          password: fieldErrors['password'] ?? [],
        };
      }
    }

    return false;
  }

  /**
   * Checks for any interrupted session and navigates to the appropriate route.
   *
   * If a previous session is stored in the storage service, it retrieves the
   * tenant information and the route to navigate to. It then selects the tenant
   * and stores the active tenant and dashboard configuration in the storage
   * service. Finally, it navigates to the previous route.
   *
   * If no previous session is stored, it navigates to the 'evidencije' route.
   *
   * If an error occurs during the process, it handles the error using the error
   * handler service.
   */
  private async interruptedSession(): Promise<void> {
    if (!this.secureLocalStorage.has('previousSession')) {
      this.router.navigateByUrl('evidencije');
      return;
    }

    const previousSession = this.secureLocalStorage
      .get('previousSession')
      .toJSON().value;
    const url = previousSession['route'];
    const id = previousSession['tenantId'];

    try {
      const tenant = await this.restApiClient
        .method('POST')
        .route('/tenant/select')
        .body({ id })
        .withAuthToken()
        .response<ActiveTenant>();

      this.secureLocalStorage
        .setValue(tenant)
        .toString()
        .encrypt()
        .store('activeTenant');
      this.secureLocalStorage
        .setValue(tenant.memberPreferences.dashboardLayout)
        .store('dashboardConfiguration');

      this.router.navigateByUrl(url);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
    this.secureLocalStorage.remove('previousSession');
  }

  /**
   * Attempts to log in the user with the provided email and password.
   *
   * If the login form is invalid, it displays an error message.
   *
   * If the login is successful, it stores the authentication token and user
   * information in the storage service, and then checks for any interrupted
   * session.
   *
   * If an error occurs during the login process, it handles the error using the
   * error handler service.
   */
  public async login(): Promise<void> {
    this.formSubmitted = true;

    if (!this.validation()) {
      return;
    }

    this.waitingForResponse = true;

    try {
      const authentication = await this.restApiClient
        .method('POST')
        .route('/login')
        .body(this.user)
        .onError('noNavigation')
        .response<Authentication>();

      cacheStorageImage(authentication.user.profilePhoto, 'avatar');

      this.secureLocalStorage
        .setValue(authentication.authToken)
        .toString()
        .encrypt()
        .store('authToken');
      this.secureLocalStorage
        .setValue(authentication.user)
        .toString()
        .store('user');

      this.interruptedSession();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.waitingForResponse = false;
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
