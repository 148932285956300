<ng-container *ngIf="isReady$ | async">
  <div class="content-container">
    <form
      #passwordForm="ngForm"
      class="form-container"
      (ngSubmit)="sendEmailForPasswordRecovery()"
    >
      <div class="form-header">
        <div class="logo-container">
          <img
            src="/assets/img/logo/logo-name-125.png"
            srcset="
              /assets/img/logo/logo-name-125.png 125w,
              assets/img/logo/logo-name-100.png  100w,
              assets/img/logo/logo-name-75.png    75w,
              assets/img/logo/logo-name-50.png    50w
            "
            sizes="(max-width: 400px) 50px, (max-width: 500px) 75px,
                  (max-width: 768px) 100px, 125px"
            alt="Swing Consulting logo"
          />
        </div>

        <div class="form-content">
          <h3>{{ "changePassword" | translation : componentName }}</h3>
          <ng-container *ngIf="!gotSuccessfulResponse; else successfulResponse">
            <span class="form-note">{{
              "enterRegisteredEmail" | translation : componentName
            }}</span>
          </ng-container>
        </div>

        <ng-template #successfulResponse>
          <span class="form-note">
            {{ "sentEmailForPasswordRecovery" | translation : componentName }}
          </span>
          <span class="form-note">{{
            "checkEmailInbox" | translation : componentName
          }}</span>
        </ng-template>
      </div>

      <ng-container *ngIf="!gotSuccessfulResponse">
        <div class="form-content">
          <div class="form-control-group">
            <div>
              <label for="email">{{
                "email" | translation : componentName
              }}</label>
              <span class="required-field">*</span>
            </div>
            <input
              pInputText
              class="form-input"
              [ngClass]="
                formSubmitted && email.errors ? 'invalid-field' : 'valid-field'
              "
              id="email"
              name="email"
              #email="ngModel"
              [(ngModel)]="user.email"
              required
              email
            />
            <small class="validation-error">
              {{ validationError.email[0] | translation : componentName }}
            </small>
          </div>

          <button
            pButton
            type="submit"
            class="form-submit-button"
            label="{{ 'sendEmail' | translation : componentName }}"
            [disabled]="waitingForResponse"
          ></button>
        </div>
      </ng-container>
    </form>
  </div>
</ng-container>
