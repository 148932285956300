<div class="content-container">
  <ng-container *ngIf="isReady$ | async; else loader">
    <select-tenant
      [userName]="userName"
      [tenants]="tenants"
      [activeInvites]="activeInvites"
      (onLogout)="logout()"
      (onToggleSidebar)="toggleSidebar($event)"
      (onSelectTenant)="selectTenant($event)"
      (onSendInvite)="sendInvite($event)"
    ></select-tenant>

    <create-tenant-sidebar
      [visible]="tenantSidebarVisible"
      [license]="license"
      (onHideSidebar)="toggleSidebar('tenant')"
      (onCreateTenant)="storeTenant($event)"
    ></create-tenant-sidebar>

    <received-tenant-invites-sidebar
      [visible]="inviteSidebarVisible"
      [invites]="invites"
      (onHideSidebar)="toggleSidebar('invite')"
      (onHandleInvite)="handleInvite($event)"
    ></received-tenant-invites-sidebar>
  </ng-container>

  <ng-template #loader>
    <loader></loader>
  </ng-template>
</div>
