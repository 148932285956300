import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { NgIf } from '@angular/common';

import { DividerModule } from 'primeng/divider';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';

import { TranslationPipe } from '@pipes/translation.pipe';
import { TranslationService } from '@services/translation.service';
import {Subscription} from "rxjs";
import {InboxDocumentsService} from "@services/inbox-documents.service";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgIf, TranslationPipe, DividerModule, MenubarModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public readonly componentName: string = 'HeaderComponent';
  @Input() public userName: string = '';
  @Input() public userAvatar: null | string = '';
  @Input() public tenantName: string = '';
  @Input() public tenantInboxSize: number = 0;
  @Output() public onToggleSidebar: EventEmitter<void> =
    new EventEmitter<void>();
  public items: Array<MenuItem> = [];
  subscription!: Subscription;

  constructor(
    private translationService: TranslationService,
    private inboxDocumentsService: InboxDocumentsService)
  {}

  public ngOnInit(): void {
    this.setItems();
    this.subscription = this.inboxDocumentsService.inboxDocumentCount.subscribe(
      value => {
        this.tenantInboxSize = value;
      }
    );
  }

  /**
   * Sets the menu items for the header component.
   * The menu items include links to various pages such as incoming documents,
   * outgoing documents, partners, evidence, and settings.
   * Each menu item has a label, a router link, optional query parameters,
   * and a style class.
   * Some menu items also have an icon and a tooltip with a label and position.
   */
  private setItems(): void {
    this.items = [
      {
        label: 'inboxDocuments',
        routerLink: 'pretinac-za-dokumente',
        styleClass: 'header-item',
      },
      {
        label: 'incomingDocuments',
        routerLink: 'ulazni-dokumenti',
        styleClass: 'header-item',
      },
      {
        label: 'outgoingDocuments',
        routerLink: 'izlazni-dokumenti',
        styleClass: 'header-item',
      },
      {
        label: 'partners',
        routerLink: 'partneri',
        styleClass: 'header-item',
      },
      {
        icon: 'pi pi-list',
        routerLink: 'evidencije',
        tooltipOptions: {
          tooltipLabel: this.translationService.getInstant(
            'tenants',
            this.componentName
          ),
          tooltipPosition: 'bottom',
        },
        styleClass: 'header-item',
      },
      {
        icon: 'pi pi-cog',
        routerLink: 'postavke',
        tooltipOptions: {
          tooltipLabel: this.translationService.getInstant(
            'settings',
            this.componentName
          ),
          tooltipPosition: 'bottom',
        },
        styleClass: 'header-item',
      },
    ];
  }

  /**
   * Emits an event to toggle the sidebar.
   */
  public toggleSidebar(): void {
    this.onToggleSidebar.emit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
