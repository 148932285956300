<ng-container *ngIf="isReady$ | async">
  <div class="content-container">
    <form #signupForm="ngForm" class="form-container" (ngSubmit)="signup()">
      <div class="form-header">
        <div class="logo-container">
          <img
            src="/assets/img/logo/logo-name-125.png"
            srcset="
              /assets/img/logo/logo-name-125.png 125w,
              assets/img/logo/logo-name-100.png  100w,
              assets/img/logo/logo-name-75.png    75w,
              assets/img/logo/logo-name-50.png    50w
            "
            sizes="(max-width: 400px) 50px, (max-width: 500px) 75px,
                  (max-width: 768px) 100px, 125px"
            alt="Swing Consulting logo"
          />
        </div>
      </div>

      <div class="form-content">
        <div class="form-control-group">
          <div>
            <label for="firstName">{{
              "firstName" | translation : componentName
            }}</label>
            <span class="required-field">*</span>
          </div>
          <input
            pInputText
            class="form-input"
            [ngClass]="
              formSubmitted && firstName.errors
                ? 'invalid-field'
                : 'valid-field'
            "
            id="firstName"
            name="firstName"
            #firstName="ngModel"
            [(ngModel)]="user.firstName"
            required
          />
          <small class="validation-error">
            {{ validationError.firstName[0] | translation : componentName }}
          </small>
        </div>

        <div class="form-control-group">
          <div>
            <label for="lastName">{{
              "lastName" | translation : componentName
            }}</label>
            <span class="required-field">*</span>
          </div>
          <input
            pInputText
            class="form-input"
            [ngClass]="
              formSubmitted && lastName.errors ? 'invalid-field' : 'valid-field'
            "
            id="lastName"
            name="lastName"
            #lastName="ngModel"
            [(ngModel)]="user.lastName"
            required
          />
          <small class="validation-error">
            {{ validationError.lastName[0] | translation : componentName }}
          </small>
        </div>

        <div class="form-control-group">
          <div>
            <label for="email">{{
              "email" | translation : componentName
            }}</label>
            <span class="required-field">*</span>
          </div>
          <input
            pInputText
            class="form-input"
            [ngClass]="
              formSubmitted && email.errors ? 'invalid-field' : 'valid-field'
            "
            id="email"
            name="email"
            #email="ngModel"
            [(ngModel)]="user.email"
            required
            email
          />
          <small class="validation-error">
            {{ validationError.email[0] | translation : componentName }}
          </small>
        </div>

        <div class="form-control-group">
          <div>
            <label for="password">{{
              "password" | translation : componentName
            }}</label>
            <span class="required-field">*</span>
          </div>
          <p-password
            class="password-component"
            styleClass="password-wrapper"
            [inputStyleClass]="
              formSubmitted && password.errors
                ? 'form-input invalid-field'
                : 'form-input valid-field'
            "
            inputId="password"
            name="password"
            #password="ngModel"
            [(ngModel)]="user.password"
            [toggleMask]="true"
            promptLabel="{{ 'enter' | translation : componentName }}"
            weakLabel="{{ 'weak' | translation : componentName }}"
            mediumLabel="{{ 'medium' | translation : componentName }}"
            strongLabel="{{ 'strong' | translation : componentName }}"
            strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$"
            required
            [passwordStrength]="strongRegex"
          >
            <ng-template pTemplate="footer">
              <p-divider />
              <ul class="password-requirements-list">
                <li>{{ "lowercase" | translation : componentName }}</li>
                <li>{{ "uppercase" | translation : componentName }}</li>
                <li>{{ "numeric" | translation : componentName }}</li>
                <li>{{ "special" | translation : componentName }}</li>
                <li>{{ "minimum" | translation : componentName }}</li>
              </ul>
            </ng-template>
          </p-password>
          <small class="validation-error">
            {{ validationError.password[0] | translation : componentName }}
          </small>
        </div>

        <button
          pButton
          type="submit"
          class="form-submit-button"
          label="{{ 'signup' | translation : componentName }}"
          [disabled]="waitingForResponse"
        ></button>
      </div>
    </form>

    <div class="signup-link">
      <a routerLink="/prijava">{{ "login" | translation : componentName }}</a>
    </div>
  </div>
</ng-container>
