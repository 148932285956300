<footer>
  <div class="footer-content">
    <div class="copyright">
      <span>© 2024</span>
      <a href="https://swing-consulting.hr/" target="_blank">
        SWING Consulting d.o.o.
      </a>
    </div>
    <div class="logo">
      <img
        class="company-avatar"
        src="assets/img/logo/logo-32.png"
        srcset="
          assets/img/logo/logo-32.png 32w,
          assets/img/logo/logo-48.png 48w,
          assets/img/logo/logo-64.png 64w
        "
        sizes="(max-width: 1024px) 32px, (max-width: 1440px) 48px, 64px"
        alt="Swing Consulting logo"
      />
    </div>
    <div class="links">
      <a
        class="support-link"
        href="https://support.swing-consulting.hr/portal/hr/home"
        target="_blank"
      >
        {{ "support" | translation : componentName }}
      </a>
    </div>
  </div>
</footer>
