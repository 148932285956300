<ng-container *ngIf="isReady$ | async">
  <div class="content-container">
    <form #loginForm="ngForm" class="form-container" (ngSubmit)="login()">
      <div class="form-header">
        <div class="logo-container">
          <img
            src="/assets/img/logo/logo-name-125.png"
            srcset="
              /assets/img/logo/logo-name-125.png 125w,
              assets/img/logo/logo-name-100.png  100w,
              assets/img/logo/logo-name-75.png    75w,
              assets/img/logo/logo-name-50.png    50w
            "
            sizes="(max-width: 400px) 50px, (max-width: 500px) 75px,
                  (max-width: 768px) 100px, 125px"
            alt="Swing Consulting logo"
          />
        </div>
      </div>

      <div class="form-content">
        <div class="form-control-group">
          <div>
            <label for="email">{{
              "email" | translation : componentName
            }}</label>
            <span class="required-field">*</span>
          </div>
          <input
            pInputText
            class="form-input"
            [ngClass]="
              formSubmitted && email.errors ? 'invalid-field' : 'valid-field'
            "
            id="email"
            name="email"
            #email="ngModel"
            [(ngModel)]="user.email"
            required
            email
          />
          <small class="validation-error">
            {{ validationError.email[0] | translation : componentName }}
          </small>
        </div>

        <div class="form-control-group">
          <div>
            <label for="password">{{
              "password" | translation : componentName
            }}</label>
            <span class="required-field">*</span>
          </div>
          <p-password
            class="password-component"
            styleClass="password-wrapper"
            [inputStyleClass]="
              formSubmitted && password.errors
                ? 'form-input invalid-field'
                : 'form-input valid-field'
            "
            inputId="password"
            name="password"
            #password="ngModel"
            [(ngModel)]="user.password"
            [toggleMask]="true"
            [feedback]="false"
            required
          />
          <small class="validation-error">
            {{ validationError.password[0] | translation : componentName }}
          </small>
        </div>

        <button
          pButton
          type="submit"
          class="form-submit-button"
          label="{{ 'login' | translation : componentName }}"
          [disabled]="waitingForResponse"
        ></button>
      </div>

      <div class="form-footer">
        <a routerLink="/lozinka">{{
          "forgotPassword" | translation : componentName
        }}</a>
      </div>
    </form>

    <div class="signup-link">
      <a routerLink="/registracija">{{
        "signup" | translation : componentName
      }}</a>
    </div>
  </div>
</ng-container>
