import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { TranslationPipe } from '@pipes/translation.pipe';
import { ServerError } from '@models/server-error';
import { ErrorHandlerService } from '@services/error-handler.service';
import { RestApiClient } from '@models/rest.api-client';
import { SecureLocalStorage } from '@models/secure-local-storage';

@Component({
  selector: 'logout',
  standalone: true,
  imports: [TranslationPipe],
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {
  public readonly componentName: string = 'LogoutComponent';
  public timeout: number = 0;
  private restApiClient: RestApiClient;
  private secureLocalStorage: SecureLocalStorage;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.restApiClient = new RestApiClient();
    this.secureLocalStorage = new SecureLocalStorage();
  }

  public ngOnInit(): void {
    this.logout();
  }

  /**
   * Logs out the current user by making a POST request to the '/logout' endpoint,
   * and then handles the logout process.
   *
   * @async
   * @returns {Promise<void>} - A Promise that resolves when the logout process is complete.
   * @throws {ServerError} - If there is an error during the logout process.
   */
  private async logout(): Promise<void> {
    try {
      await this.restApiClient
        .method('POST')
        .route('/logout')
        .withAuthToken()
        .response<string>();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.handleLogout();
  }

  /**
   * Handles the logout process by clearing the user's storage, deleting all cookies,
   * and navigating to the login page after a 2.5 second delay.
   */
  private handleLogout() {
    this.secureLocalStorage.clear();
    this.cookieService.deleteAll();

    this.timeout = window.setTimeout(() => {
      this.router.navigate(['/prijava']);
    }, 2500);
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
