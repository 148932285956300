import { Component } from '@angular/core';

import { TranslationPipe } from '@pipes/translation.pipe';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslationPipe],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public readonly componentName: string = 'FooterComponent';
}
