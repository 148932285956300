<ng-container *ngIf="isReady$ | async">
  <div class="content-container">
    <form
      #passwordForm="ngForm"
      class="form-container"
      (ngSubmit)="resetPassword()"
    >
      <div class="form-header">
        <div class="logo-container">
          <img
            src="/assets/img/logo/logo-name-125.png"
            srcset="
              /assets/img/logo/logo-name-125.png 125w,
              assets/img/logo/logo-name-100.png  100w,
              assets/img/logo/logo-name-75.png    75w,
              assets/img/logo/logo-name-50.png    50w
            "
            sizes="(max-width: 400px) 50px, (max-width: 500px) 75px,
                  (max-width: 768px) 100px, 125px"
            alt="Swing Consulting logo"
          />
        </div>
        <div class="form-content">
          <h3>{{ "changePassword" | translation : componentName }}</h3>
          <span class="form-note">{{
            "enterNewPassword" | translation : componentName
          }}</span>
        </div>
      </div>

      <div class="form-content">
        <div class="form-control-group">
          <div>
            <label for="password">{{
              "password" | translation : componentName
            }}</label>
            <span class="required-field">*</span>
          </div>
          <p-password
            class="password-component"
            styleClass="password-wrapper"
            [inputStyleClass]="
              formSubmitted && password.errors
                ? 'form-input invalid-field'
                : 'form-input valid-field'
            "
            inputId="password"
            name="password"
            #password="ngModel"
            [(ngModel)]="user.password"
            [toggleMask]="true"
            promptLabel="{{ 'enter' | translation : componentName }}"
            weakLabel="{{ 'weak' | translation : componentName }}"
            mediumLabel="{{ 'medium' | translation : componentName }}"
            strongLabel="{{ 'strong' | translation : componentName }}"
            strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            required
            [passwordStrength]="strongRegex"
          >
            <ng-template pTemplate="footer">
              <p-divider />
              <ul class="password-requirements-list">
                <li>{{ "lowercase" | translation : componentName }}</li>
                <li>{{ "uppercase" | translation : componentName }}</li>
                <li>{{ "numeric" | translation : componentName }}</li>
                <li>{{ "special" | translation : componentName }}</li>
                <li>{{ "minimum" | translation : componentName }}</li>
              </ul>
            </ng-template>
          </p-password>
          <small class="validation-error">
            {{ validationError.password[0] | translation : componentName }}
          </small>
        </div>

        <button
          pButton
          type="submit"
          class="form-submit-button"
          label="{{ 'resetPassword' | translation : componentName }}"
          [disabled]="waitingForResponse"
        ></button>
      </div>
    </form>
  </div>
</ng-container>
