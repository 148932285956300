import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { ServerError } from '@models/server-error';
import { User } from '@interfaces/authentication';
import { RestApiClient } from '@models/rest.api-client';
import { ErrorHandlerService } from '@services/error-handler.service';
import { SecureLocalStorage } from '@models/secure-local-storage';

export const registrationGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const errorHandlerService = inject(ErrorHandlerService);
  const restApiClient = new RestApiClient();

  try {
    const token: string = route.params['verifyToken'] ?? '';

    await restApiClient
      .method('GET')
      .route(`/verify/${token}`)
      .withAuthToken()
      .response<any>();

    updateUserInStorage();

    router.navigateByUrl('/evidencije');
  } catch (error) {
    errorHandlerService.handleError(new ServerError(error, 'navigateToLogin'));
  }

  return true;
};

const updateUserInStorage = () => {
  const secureLocalStorage = new SecureLocalStorage();

  if (!secureLocalStorage.has('user')) {
    return;
  }

  let user: User = secureLocalStorage.get('user').toJSON().value;
  user = { ...user, verified: true };

  secureLocalStorage.setValue(user).toString().store('user');
};
