export function cacheStorageImage(imageUrl: string, cacheName: string): void {
  fetch(imageUrl)
    .then(async (response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const cache = await caches.open(cacheName);
      return await cache.put(imageUrl, response.clone());
    })
    .catch((error) => {
      console.error('Caching failed:', error);
    });
}

export async function fetchStorageImage(
  imageUrl: string,
  cacheName: string
): Promise<string | null> {
  const cache = await caches.open(cacheName);
  const cacheResponse = await cache.match(imageUrl);

  if (cacheResponse) {
    const cacheBlob = await cacheResponse.blob();
    return URL.createObjectURL(cacheBlob);
  } else {
    const networkResponse = await fetch(imageUrl);
    if (networkResponse.ok) {
      const networkBlob = await networkResponse.blob();
      cacheStorageImage(imageUrl, cacheName);
      return URL.createObjectURL(networkBlob);
    } else {
      console.error('Network fetch failed:', networkResponse.status);
      return null;
    }
  }
}
