import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { SecureLocalStorage } from '@models/secure-local-storage';

export const authenticationGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const secureLocalStorage = new SecureLocalStorage();

  if (!secureLocalStorage.has('authToken')) {
    router.navigate(['/prijava']);
    return false;
  }

  return true;
};
