<p-sidebar
  styleClass="sidebar-wrapper"
  [(visible)]="visible"
  position="right"
  transitionOptions="0.5s"
  (onHide)="hideSidebar()"
>
  <ng-template pTemplate="headless">
    <div class="user-info">
      <button
        pButton
        class="p-button-text close-button"
        icon="pi pi-times"
        (click)="hideSidebar()"
      ></button>
      <img
        class="user-avatar"
        [src]="userAvatar"
        alt="User avatar"
        height="85"
        width="85"
      />
      <h3>{{ userName }}</h3>
      <div>{{ userEmail }}</div>
      <div>{{ companyName }}</div>
    </div>
    <div class="button-container">
      <button
        pButton
        class="p-button-text action-button"
        label="{{ 'administration' | translation : componentName }}"
        (click)="navigateTo('administracija')"
      ></button>
      <button
        pButton
        class="p-button-text action-button"
        label="{{ 'logout' | translation : componentName }}"
        (click)="navigateTo('odjava')"
      ></button>
    </div>
  </ng-template>
</p-sidebar>
