<p-menubar styleClass="header" [model]="items">
  <ng-template pTemplate="start">
    <div class="menubar-start">
      <a routerLink="/">
        <img
          src="assets/img/logo/logo-white.png"
          alt="Swing Consulting logo"
          height="40"
        />
      </a>
    </div>
  </ng-template>

  <ng-template pTemplate="item" let-item>
    <a
      class="module-link"
      [routerLink]="item.routerLink"
      routerLinkActive="active-link"
    >
      <ng-container *ngIf="item.label; else icon">
        <span class="module-label">
          {{ item.label | translation : componentName }}
          <ng-container *ngIf="item.label === 'inboxDocuments'">
            <span class="module-label inbox">
             ({{tenantInboxSize}})
            </span>
          </ng-container>
        </span>
      </ng-container>

      <ng-template #icon>
        <i class="{{ item.icon }} module-label icon"></i>
      </ng-template>
    </a>
  </ng-template>

  <ng-template pTemplate="end">
    <div class="menubar-end">
      <p-divider styleClass="divider" layout="vertical"></p-divider>
      <div class="user-info" (click)="toggleSidebar()">
        <img
          class="user-avatar"
          [src]="userAvatar"
          alt="User avatar"
          height="48"
        />
        <div class="names">
          <div>{{ userName }}</div>
          <div>{{ tenantName }}</div>
        </div>
      </div>
    </div>
  </ng-template>
</p-menubar>
