import { Component, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { ButtonModule } from 'primeng/button';

import { TranslationPipe } from '@pipes/translation.pipe';
import { ServerError } from '@models/server-error';
import { ErrorHandlerService } from '@services/error-handler.service';
import { RestApiClient } from '@models/rest.api-client';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'verification',
  standalone: true,
  imports: [NgIf, AsyncPipe, TranslationPipe, ButtonModule],
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit, OnDestroy {
  public readonly componentName: string = 'VerificationComponent';
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public waitingForResponse: boolean = false;
  public timeout: number = 0;
  private restApiClient: RestApiClient;

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService
  ) {
    this.restApiClient = new RestApiClient();
  }

  public ngOnInit(): void {
    this.timeout = window.setTimeout(() => {
      this.isReady$.next(true);
      this.router.navigateByUrl('/evidencije');
    }, 500);
  }

  /**
   * Resends the verification email to the user.
   *
   * This method is responsible for making a GET request to the '/verify/send' endpoint
   * with the user's authentication token. If the request is successful, a success message
   * is displayed to the user. If there is an error, the error is handled by the
   * `ErrorHandlerService`.
   *
   * The `waitingForResponse` flag is set to `true` before the request is made and set to
   * `false` after the request is completed, regardless of the outcome.
   */
  public async resendVerificationEmail(): Promise<void> {
    this.waitingForResponse = true;

    try {
      await this.restApiClient
        .method('GET')
        .route('/verify/send')
        .withAuthToken()
        .response<void>();

      this.messageService
        .translate(this.componentName)
        .success('resendVerificationEmail')
        .showMessage();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }

    this.waitingForResponse = false;
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
