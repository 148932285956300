import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PanelModule } from 'primeng/panel';

import { TranslationPipe } from '@pipes/translation.pipe';
import { RestApiClient } from '@models/rest.api-client';
import { CookieConsent } from '@interfaces/cookie-consent';
import { Message } from '@interfaces/alert';
import { ErrorHandlerService } from '@services/error-handler.service';

@Component({
  selector: 'cookie-consent',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    AsyncPipe,
    TranslationPipe,
    ButtonModule,
    DialogModule,
    InputSwitchModule,
    PanelModule,
  ],
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  providers: [CookieService],
})
export class CookieConsentComponent implements OnInit, OnDestroy {
  public readonly componentName: string = 'CookieConsentComponent';
  @Output() public onAcceptCookieConsent: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  public isReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public cookieConsent: CookieConsent = {
    required: true,
    bugReport: false,
    deviceInfo: false,
    location: false,
  };
  public cookieSettingsVisible: boolean = false;
  public timeout: number = 0;
  private restApiClient: RestApiClient;

  constructor(
    private cookieService: CookieService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.restApiClient = new RestApiClient();
  }

  public ngOnInit(): void {
    // timeout so that translation in main.ts can be fetched and used in pipe
    this.timeout = window.setTimeout(() => {
      this.isReady.next(true);
    }, 500);
  }

  /**
   * Accepts all cookies by setting the `cookieConsent` object to have all
   * properties set to `true`.
   */
  private acceptAllCookies(): void {
    this.cookieConsent = {
      required: true,
      bugReport: true,
      deviceInfo: true,
      location: true,
    };
  }

  /**
   * Accepts all cookies by setting the `cookieConsent` object to have all
   * properties set to `true`, and then calls the `consent()` method to save
   * the cookie consent.
   *
   * @param type - The type of cookie consent to accept. If 'all', it will
   * accept all cookies.
   */
  public acceptCookies(type: string): void {
    if (type === 'all') {
      this.acceptAllCookies();
    }

    this.consent();
  }

  /**
   * Shows the cookie settings UI.
   *
   * This method sets the `cookieSettingsVisible` property to `true`, which will
   * display the cookie consent UI to the user. This allows the user to view and
   * modify their cookie consent preferences.
   */
  public showCookieSettings(): void {
    this.cookieSettingsVisible = true;
  }

  /**
   * Hides the cookie settings UI.
   *
   * This method sets the `cookieSettingsVisible` property to `false`, which will
   * hide the cookie consent UI from the user.
   */
  public hideCookieSettings(): void {
    this.cookieSettingsVisible = false;
  }

  /**
   * Sends the user's cookie consent preferences to the server and saves the
   * consent in a cookie.
   *
   * This method is called when the user accepts the cookie consent. It sends
   * the `cookieConsent` object, which contains the user's preferences for different
   * types of cookies, to the server using an HTTP POST request to the `/api/consent`
   * endpoint. If the request is successful, the consent is saved in a cookie with an
   * expiration of 7 days. The `cookieSettingsVisible` property is set to `false` to
   * hide the cookie consent UI, and the `onAcceptCookieConsent` event is emitted to
   * notify any parent components that the user has accepted the cookie consent.
   *
   * If an error occurs during the request, the `errorHandlerService` is used to
   * handle the error.
   */
  public async consent(): Promise<void> {
    try {
      await this.restApiClient
        .method('POST')
        .route('/api/consent')
        .body({ consent: this.cookieConsent })
        .response<Message>();

      this.cookieService.set('consent', JSON.stringify(this.cookieConsent), {
        expires: 7,
      });

      this.cookieSettingsVisible = false;
      this.onAcceptCookieConsent.emit(true);
    } catch (error) {
      this.errorHandlerService.handleError(error);
    }
  }

  public ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
