import { Injectable } from '@angular/core';
import {
  MissingTranslationHandler as TranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MissingTranslationHandlerService implements TranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      return Object.values(params.interpolateParams)[0];
    }

    return params.key;
  }
}
